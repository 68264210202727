<template>
  <TTView>
    <VCard :loading="loadingData">
      <VContainer>
        <VRow>
          <VCol cols="auto">
            <span class="tt-text-headline-2">Добавить связь штатных должностей</span>
          </VCol>
        </VRow>
        <VForm
          ref="form"
          v-model="valid"
          class="my-3"
          lazy-validation
        >
          <VRow>
            <VCol cols="12">
              <TTAutocomplete
                v-model="fromStaffPositionId"
                :items="isChiefLink ? companyFilteredStaffPositions : staffPositions"
                :rules="idRules"
                label="От штатной должности"
                :filter="staffPositionFilter"
                item-value="id"
                item-text="name"
                :disabled="isFromStaffPositionDisabled"
                @update:search-input="searchStaffPositionInCompany($event)"
                @input="searchStaffPositionInCompany($event)"
              >
                <template #selection="{ item }">
                  <span
                    class="tt-text-body-2 ml-3"
                    v-text="item.id"
                  />
                  &nbsp;|&nbsp;
                  <span
                    class="tt-text-body-2"
                    v-text="item.name"
                  />
                </template>

                <template #item="{ item }">
                  <div>
                    <div
                      class="tt-text-body-1"
                      v-text="item.name"
                    />
                    <div
                      class="tt-text-body-2"
                      v-text="item.id"
                    />
                  </div>
                </template>
              </TTAutocomplete>
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <TTAutocomplete
                v-model="toStaffPositionId"
                :items="isChiefLink ? staffPositions : companyFilteredStaffPositions"
                :rules="idRules"
                label="К штатной должности"
                item-value="id"
                item-text="name"
                :filter="staffPositionFilter"
                :disabled="isToStaffPositionDisabled"
                @update:search-input="searchStaffPositionInCompany($event)"
                @input="searchStaffPositionInCompany($event)"
              >
                <template #selection="{ item }">
                  <span
                    class="tt-text-body-2 ml-3"
                    v-text="item.id"
                  />
                  &nbsp;|&nbsp;
                  <span
                    class="tt-text-body-2"
                    v-text="item.name"
                  />
                </template>

                <template #item="{ item }">
                  <div>
                    <div
                      class="tt-text-body-1"
                      v-text="item.name"
                    />
                    <div
                      class="tt-text-body-2"
                      v-text="item.id"
                    />
                  </div>
                </template>
              </TTAutocomplete>
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <TTTextField
                v-model="role"
                :rules="roleRules"
                label="Роль"
              />
            </VCol>
          </VRow>
        </VForm>
        <VRow>
          <VCol cols="auto">
            <TTBtn
              v-if="isComingFromOrgUserCompany"
              color="tt-secondary"
              @click="$router.go(-1)"
            >
              Назад
            </TTBtn>
          </VCol>
          <VSpacer />
          <VCol cols="auto">
            <TTBtn
              color="primary"
              :disable="!valid || loadingData"
              :loading="loading"
              @click="createStaffPositionLink"
            >
              Добавить
            </TTBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
  </TTView>
</template>

<script>
// TODO: временное решение https://talenttechgroup.slack.com/archives/C013F4E0DPF/p1629270318390400

export default {
  name: 'OrgPositionLink',

  data() {
    return {
      valid: true,
      toStaffPositionId: null,
      fromStaffPositionId: null,
      staffPositionCompanyId: null,
      companyFilteredStaffPositions: [],
      role: 'chief',
      loading: false,
      loadingData: false,
      idRules: [
        (v) => !!v || 'Выберите штатную должность',
        () => this.toStaffPositionId !== this.fromStaffPositionId || 'Нельзя связать штатную должность саму с собой',
      ],
      roleRules: [
        (v) => !!v || 'Заполните поле',
        (v) => (v.length > 0 && v.length < 33) || 'Максимальная длинна 32 символа',
        (v) => /^[a-z0-9._-]+$/.test(v) || 'Допустимые символы a-z, 0-9 и символы _-.',
        (v) => /^[a-z]+$/.test(v[0]) || 'Может начинаться только с буквы',
      ],
      staffPositions: [],
    };
  },

  computed: {
    isFromStaffPositionDisabled() {
      if (!this.isChiefLink) {
        return !!this.fromStaffPosition || !!this.toStaffPositionId;
      }
      return !this.toStaffPositionId;
    },
    isToStaffPositionDisabled() {
      if (!this.isChiefLink) {
        return !this.fromStaffPositionId;
      }
      return !!this.fromStaffPosition || !!this.fromStaffPositionId;
    },
    fromStaffPosition() {
      if (this.staffPositionId) {
        return this.staffPositions.find((sp) => sp.id === this.staffPositionId);
      }
      return null;
    },
    staffPositionId() {
      return this.$route.query.staffPositionId;
    },
    isChiefLink() {
      return this.$route.query.chief;
    },
    isNoRole() {
      return this.$route.query.noRole;
    },
    isComingFromOrgUserCompany() {
      return this.$route.params.staffPositionId;
    },
  },

  watch: {
    async fromStaffPosition(val) {
      if (!this.isChiefLink) {
        this.fromStaffPositionId = val.id;
      } else {
        this.toStaffPositionId = val.id;
      }
    },
    fromStaffPositionId(val) {
      this.findSelectedCompanyId(val);
    },
    toStaffPositionId(val) {
      this.findSelectedCompanyId(val);
    },
    role() {
      this.role = this.role?.toLowerCase();
    },
  },

  async created() {
    try {
      this.loadingData = true;

      if (this.isNoRole) {
        this.role = '';
      }

      // TODO: ручка выдает все штатки, не фильтруя по компаниям (переделать)
      const { staffPositions = [] } = await this.$di.api.Orgstructure.positionSearch({
        name: '',
        personId: '',
        teamId: '',
        main: 'MAIN_ALL',
      });
      this.staffPositions = staffPositions;
    } catch (err) {
      this.$di.notify.errorHandler(err);
    } finally {
      this.loadingData = false;
    }
  },

  methods: {
    async findSelectedCompanyId(val) {
      if (val) {
        const { staffPositions } = await this.$di.api.Orgstructure.staffPositionsByIds({ ids: [val] });
        this.staffPositionCompanyId = staffPositions.find((el) => el.id === val)?.companyId;
      }
    },
    async searchStaffPositionInCompany(value) {
      if (value) {
        const companyResponse = await this.$di.api.Orgstructure.searchStaffPositionsInCompany({
          query: value,
          companyId: this.staffPositionCompanyId,
        });

        this.companyFilteredStaffPositions = companyResponse.staffPositions;
      } else {
        this.companyFilteredStaffPositions = [];
      }
    },
    staffPositionFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },
    async createStaffPositionLink() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const { role, toStaffPositionId, fromStaffPositionId } = this;
        const app = new this.$di.ddd.Orgstructure();
        try {
          await app.services.team.linkStaffPositions({
            toStaffPositionId,
            fromStaffPositionId,
            role,
          });
          this.$di.notify.snackSuccess('Связь добавлена');
          this.clearForm();
        } catch (err) {
          this.$di.notify.errorHandler(err);
        } finally {
          this.loading = false;
        }
      }
    },
    clearForm() {
      if (this.isChiefLink) {
        this.fromStaffPositionId = null;
      } else {
        this.toStaffPositionId = null;
        if (!this.fromStaffPosition) {
          this.fromStaffPositionId = null;
        }
      }
      this.companyFilteredStaffPositions = [];
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
/* HOTFIX */
::v-deep .v-select.v-text-field input {
  position: relative;
}
</style>
