var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VCard',{attrs:{"loading":_vm.loadingData}},[_c('VContainer',[_c('VRow',[_c('VCol',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"tt-text-headline-2"},[_vm._v("Добавить связь штатных должностей")])])],1),_c('VForm',{ref:"form",staticClass:"my-3",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('VRow',[_c('VCol',{attrs:{"cols":"12"}},[_c('TTAutocomplete',{attrs:{"items":_vm.isChiefLink ? _vm.companyFilteredStaffPositions : _vm.staffPositions,"rules":_vm.idRules,"label":"От штатной должности","filter":_vm.staffPositionFilter,"item-value":"id","item-text":"name","disabled":_vm.isFromStaffPositionDisabled},on:{"update:search-input":function($event){return _vm.searchStaffPositionInCompany($event)},"input":function($event){return _vm.searchStaffPositionInCompany($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tt-text-body-2 ml-3",domProps:{"textContent":_vm._s(item.id)}}),_vm._v("  |  "),_c('span',{staticClass:"tt-text-body-2",domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"tt-text-body-1",domProps:{"textContent":_vm._s(item.name)}}),_c('div',{staticClass:"tt-text-body-2",domProps:{"textContent":_vm._s(item.id)}})])]}}]),model:{value:(_vm.fromStaffPositionId),callback:function ($$v) {_vm.fromStaffPositionId=$$v},expression:"fromStaffPositionId"}})],1)],1),_c('VRow',[_c('VCol',{attrs:{"cols":"12"}},[_c('TTAutocomplete',{attrs:{"items":_vm.isChiefLink ? _vm.staffPositions : _vm.companyFilteredStaffPositions,"rules":_vm.idRules,"label":"К штатной должности","item-value":"id","item-text":"name","filter":_vm.staffPositionFilter,"disabled":_vm.isToStaffPositionDisabled},on:{"update:search-input":function($event){return _vm.searchStaffPositionInCompany($event)},"input":function($event){return _vm.searchStaffPositionInCompany($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tt-text-body-2 ml-3",domProps:{"textContent":_vm._s(item.id)}}),_vm._v("  |  "),_c('span',{staticClass:"tt-text-body-2",domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"tt-text-body-1",domProps:{"textContent":_vm._s(item.name)}}),_c('div',{staticClass:"tt-text-body-2",domProps:{"textContent":_vm._s(item.id)}})])]}}]),model:{value:(_vm.toStaffPositionId),callback:function ($$v) {_vm.toStaffPositionId=$$v},expression:"toStaffPositionId"}})],1)],1),_c('VRow',[_c('VCol',{attrs:{"cols":"12"}},[_c('TTTextField',{attrs:{"rules":_vm.roleRules,"label":"Роль"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1)],1)],1),_c('VRow',[_c('VCol',{attrs:{"cols":"auto"}},[(_vm.isComingFromOrgUserCompany)?_c('TTBtn',{attrs:{"color":"tt-secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Назад ")]):_vm._e()],1),_c('VSpacer'),_c('VCol',{attrs:{"cols":"auto"}},[_c('TTBtn',{attrs:{"color":"primary","disable":!_vm.valid || _vm.loadingData,"loading":_vm.loading},on:{"click":_vm.createStaffPositionLink}},[_vm._v(" Добавить ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }